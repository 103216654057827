<template>
  <div>
    <SEARCH :searchChange="onCallbackSearch" :list="searchArr"></SEARCH>
    <TABS :list="tabsArr"></TABS>
    <TABLE
      :switchChange="onCallbackSwitch"
      :buttonChange="onCallbackButton"
      :isShowSele="false"
      :list="tableArr"
      :data="dataArr"
    ></TABLE>
    <PAGE
      :pageChange="onCallbackPage"
      :limitChange="onCallbackLimit"
      :count="count"
    ></PAGE>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { getAdmitList, updAdmin, delAdmin } from '@/api/system'
export default {
  name: 'SystemAuthManageAdminList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
  },
  data() {
    return {
      searchArr: [
        {
          name: '状态',
          type: 'picker',
          options: [],
          key: 'type',
        },
        {
          name: '姓名',
          type: 'input',
          options: [],
          key: 'name',
        },
        {
          name: '账号',
          type: 'input',
          options: [],
          key: 'username',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增管理员',
          func: '',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'nickname',
          label: '姓名',
          width: '200',
        },
        {
          prop: 'username',
          label: '账号',
          width: '200',
        },
        {
          prop: 'role_desc',
          label: '身份',
          width: '320',
          type: 'other',
        },
        {
          prop: 'login_time',
          label: '最后一次登录时间',
          width: '200',
        },
        {
          prop: 'login_ip',
          label: '最后一次登录ip',
          width: '200',
        },
        {
          prop: 'is_disable',
          label: '状态',
          width: '100',
          type: 'switch',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeAdmin',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        nickname: '', // 姓名
        username: '', // 账号
      },
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      getAdmitList(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.list.forEach((item) => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
          item.other = [
            {
              type: '',
              name: '身份名称',
              func: '',
            },
          ]
          item.is_disable = {
            status: item.is_disable === 1,
            func: 'changeStatus',
          }
        })
        this.dataArr = result.list
      })
    },

    // 修改角色状态
    changeStatus: function ({ admin_user_id: id, is_disable: disable }) {
      const data = {
        admin_user_id: id,
        is_disable: disable === 1 ? 0 : 1,
      }
      updAdmin(data).then((res) => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除管理员
    removeAdmin: function ({ admin_user_id: id }) {
      delAdmin(id).then((res) => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function (data) {
      const { name, type, username } = data
      this.formData.nickname = name
      this.formData.p_id = type
      this.formData.username = username
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function (item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function (item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function (page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function (limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
